<template>
  <div class="container">
    <div
      style="
        height: 100px;
        padding-left: 20px;
        display: flex;
        align-items: center;
      "
    >
      <span style="font-size: 13px; color: #666">搜索医院：</span>
      <el-input
        placeholder="请输入医院名称"
        v-model="hospitalname"
        style="width: 280px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="searchList"
        ></el-button>
      </el-input>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      size="small"
      style="width: 100%"
    >
      <el-table-column type="index" label="#" width="50"> </el-table-column>
      <el-table-column prop="hospitalname" label="医院名称" width="350">
      </el-table-column>
      <el-table-column label="代理区域" min-width="220">
        <template slot-scope="scope">
          <span>{{
            scope.row.provincename +
            " " +
            scope.row.cityname +
            " " +
            scope.row.districtname
          }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="220">
        <template slot-scope="scope">
          <el-button
            @click="viewDetails(scope.row)"
            type="primary"
            size="small"
            plain
            >查看</el-button
          >
          <el-button
            v-if="scope.row.qrcodeurl"
            @click="downloadQRCode(scope.row)"
            type="primary"
            size="small"
            plain
            >下载二维码</el-button
          >
          <el-button
            v-if="!scope.row.qrcodeurl"
            @click="createQRCode(scope.row)"
            type="primary"
            size="small"
            plain
            >创建二维码</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10, 15, 30]"
        :page-size="pageSize"
        layout="total,sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </div>
    <el-dialog
      append-to-body
      title="详情"
      :visible.sync="viewDetailDialog"
      v-if="viewDetailDialog"
      width="500px"
    >
      <ViewHospitalDetails :hospitalData="viewDetailObj"></ViewHospitalDetails>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api";
import ViewHospitalDetails from "../../components/ViewHospitalDetails";

export default {
  components: { ViewHospitalDetails },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      totalData: 0,
      tableData: [],
      viewDetailDialog: false,
      viewDetailObj: null,
      loginData: null,
      hospitalname: null,
      loading: false,
    };
  },
  created() {
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.initList();
  },
  methods: {
    initList() {
      this.loading = true;
      api
        .getHospitalList({
          categorycode: this.loginData.categorycode,
          userlevel: this.loginData.userlevel,
          hospitalname: this.hospitalname,
          page: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success === "1") {
            this.totalData = res.data.data.totalRecords;
            this.tableData = res.data.data.list;
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    searchList() {
      this.pageIndex = 1;
      this.initList();
    },
    viewDetails(row) {
      console.log(row);
      this.viewDetailObj = row;
      this.viewDetailDialog = true;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.initList();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.initList();
    },
    createQRCode(row) {
      this.loading = true;
      api
        .createHospitalQRCode({
          hospitalcode: row.hospitalcode,
          scenecode: row.scenecode,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success === "1") {
            console.log(res);
            this.initList();
          } else {
            this.$message({
              showClose: true,
              message: res.data.errorMsg,
              type: "warning",
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "请检查网络连接",
            type: "warning",
          });
        });
    },
    downloadQRCode(row) {
      window.open(row.qrcodeurl);
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.footer {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
}
</style>